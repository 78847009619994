footer {
    background: transparent;
    text-align: center;
    font-size: 0.9rem;
    border-top: 1px solid var(--clr-brown);
}

footer a {
    color: var(--clr-brown);
    font-size: 1rem;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 1rem;
}

.permalinks a:hover {
    color: var(--clr-ligter-brown);
}

.footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.footer__socials a {
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
    font-size: 2rem;
}


.footer__copyrights {
    color: var(--clr-brown);
    margin-bottom: 1rem;
}


/* ========== MEDIA QUERIES (SMALL DEVICES)  ========== */

@media screen and (max-width: 600px) {
        .permalinks {
            flex-direction: column;
            gap: 1em;
        }
}