.birthday__wrapper {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
}

.birthday__gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
}

.birthday__gallery_container{
    max-width: 30%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.green__btn.ctn {
    text-align: center;
}

.first__pic {
    max-width: 50%;
}

.gallery__btns {
    display: flex;
    justify-content: space-between;
}

.gallery__btns button {
    background-color: transparent;
    border: 1px solid var(--clr-brown);
    border-radius: 1rem;
    padding: 4px;
    cursor: pointer;
    width: 100px;
    color: var(--clr-ligter-brown);
}

.gallery__btns button:hover {
    border: 1px solid var(--clr-ligter-brown);
    color: var(--clr-brown);

}

.header__menu {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header__menu ul {
    display: flex;
    gap: 0.3rem;
}
.header__menu ul li {
    padding: .3rem;
    color: var(--clr-brown);
    font-size: 1rem;
}

.header__menu ul li:hover {
    color: var(--clr-ligter-brown);
    cursor: pointer;
}


.album__header {
    color: var(--clr-brown);
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
    .birthday__wrapper {
        gap: 16px;
    }

    .birthday__gallery {
        max-width: 90%;
        aspect-ratio: 1/1;
        justify-content: center;
        justify-self: center;
    }
}

@media (max-width: 992px) { 

   }
  @media (max-width: 768px) { 
    .birthday__wrapper {
        grid-template-columns: repeat(2,1fr);
    }

    .header__menu ul li {
        padding: .1rem;
        font-size: .9rem;
    }
   }
  
  @media (max-width: 576px) { 
    .birthday__wrapper {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .header__menu ul li {
        padding: 0.2rem;
        font-size: .45rem;
    }
    
  }
